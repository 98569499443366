//constants for calculations
var CHARGE_BASE_RATE = 0.0875;
var COMMISSION_BASE_RATE = 0.07;
var COMMISSION_BASE_RATE_4 = 0.055;
var COMMISSION_BASE_RATE_3 = 0.04;
var COMMISSION_BASE_RATE_2 = 0.026;
var COMMISSION_BASE_RATE_1 = 0.0125;
var BASE_PERIOD = 5;
var CAP_PERIOD = 10;
var CHARGE_INTEREST_RATE = 0.03/4.00;
var CHARGE_INTEREST_RATE_CONSTANT_STRUCTURE_5_YEARS = 0.0;
var COMMISSION_INTEREST_RATE = 0.015/4.00;
var INCREASE_FACTOR = 1.0305;
var DECREASE_FACTOR = 0.967;
var TRAIL_DEFAULT = 100;

window.get_policy_fee_by_currency = function(ccy) {
  var values = {USD: "500", SEK: "4,800", EUR: "440", NOK: "4,400", GBP: "380", DKK: "3,500", CHF: "500", AUD: "720", SGD: "720", JPY: "55,000" }; 
  var policy_fee = values[ccy];
  var period_string = $("select#period").val();
  if ((period_string == "-5") || (period_string == "-8") || (period_string == "-10")) {
    policy_fee = "0";
  }
  return policy_fee;
}

window.get_transaction_charge_by_currency = function(ccy) {
  var values = {USD: "45", SEK: "425", EUR: "40", NOK: "400", GBP: "35", DKK: "310", CHF: "45", AUD: "65", SGD: "65", JPY: "4,800" };  
  return values[ccy];
}

window.get_custody_fee_by_currency = function(ccy) {
  var values = {USD: "45", SEK: "425", EUR: "40", NOK: "400", GBP: "35", DKK: "310", CHF: "45", AUD: "65", SGD: "65", JPY: "4,800" };  
  return values[ccy];
}

window.get_ifa_rebate = function(period) {
  var ifa_rebate = $("input#rebate_input").val();
  var base_commission_rate = get_base_commission_rate_per_period_without_rebate(period);
  base_commission_rate = base_commission_rate * 100.0;

  var return_rebate = ifa_rebate;

  if (ifa_rebate > base_commission_rate) {
    return_rebate = base_commission_rate;
    $("input#rebate_input").val(return_rebate);
  }
  else if (ifa_rebate < 0.0) {
    return_rebate = 0.0;
    $("input#rebate_input").val(return_rebate);
  }

  //set order rebate
  $("input#order_rebate").val(return_rebate);
  return return_rebate*0.01; 
}

window.get_margin_rebate = function() {
  var margin_rebate = $("input#margin_rebate_hidden").val();
  return margin_rebate*0.01;
}

window.get_adjusted_commission = function() {
  var adjusted_commission = $("input#adjusted_commission_hidden").val();
  return adjusted_commission*0.01;
}

window.get_base_rate = function(period) {
  //var ifa_rebate = $("input#rebate_input").val();
  //var margin_rebate = $("input#margin_rebate_hidden").val();
  //margin_rebate = margin_rebate/100.0;
  //ifa_rebate = ifa_rebate/100.0;

  margin_rebate = get_margin_rebate();
  ifa_rebate = get_ifa_rebate(period);

  var base_rate = CHARGE_BASE_RATE;
  var base_period = BASE_PERIOD;
  var cap_period = CAP_PERIOD;

  if (period < base_period) {
    //base_rate = base_rate/base_period * (1/Math.pow(0.95,(base_period-period))) * period;
    base_rate = base_rate/base_period * 1/(Math.pow(DECREASE_FACTOR,(base_period-period))) * period;
  }
  else if (period > base_period) {
    if (period > cap_period) {
      period = cap_period;
    }
    //base_rate = base_rate/base_period * Math.pow(0.95,(period-base_period)) * period;
    base_rate = base_rate * Math.pow(INCREASE_FACTOR,(period-base_period));
  }

  return base_rate - ifa_rebate - (margin_rebate * period);    
}

window.get_base_allocation_rate_per_period = function(period) {
  //var values = {1: 98.140, 2: 96.435, 3: 94.885, 4: 93.490, 5: 92.250, 6: 91.165, 7: 90.235, 8: 89.460, 9: 88.840, 10: 88.375};
  var base_rate = get_base_rate(period);
  return 100.0 - (base_rate*100.0);
}

window.get_base_commission_rate_per_period_without_rebate = function(period) {
  adjusted_commission = get_adjusted_commission();

  var base_rate = COMMISSION_BASE_RATE;
  var base_period = BASE_PERIOD;
  var cap_period = CAP_PERIOD;

  if (period < base_period) {
    if (period == 4) {
      base_rate = COMMISSION_BASE_RATE_4;
    }
    else if (period == 3) {
      base_rate = COMMISSION_BASE_RATE_3;
    }
    else if (period == 2) {
      base_rate = COMMISSION_BASE_RATE_2;
    }
    else if (period == 1) {
      base_rate = COMMISSION_BASE_RATE_1;
    } 
  }

  base_rate = base_rate * (1+adjusted_commission);
  return base_rate;  
}

window.get_base_commission_rate_per_period = function(period) {
  var ifa_rebate = get_ifa_rebate(period);
  var base_rate = get_base_commission_rate_per_period_without_rebate(period);
  return base_rate - ifa_rebate;  
}


window.get_investment_allocation_from_allocation_rate = function(allocation_rate) {
  var cash_allocation = $("input#cash_allocation_hidden").val();
  investment_allocation = allocation_rate - cash_allocation;
  return investment_allocation;
}

window.add_decimals_to_float_string = function(float_string) {
  if(float_string.indexOf('.') === -1) {
    float_string = float_string + ".00";
  } 
  return float_string;
}

window.get_allocation_range = function(base_allocation_rate) {
  //value = "";  var values = {1: "98.140% and 100.00%", 2: "96.435% and 100.00%", 3: "94.885% and 100.00%", 4: "93.490% and 100.00%", 5: "92.250% and 100.00%", 6: "91.165% and 100.00%", 7: "90.235% and 100.00%", 8: "89.460% and 100.00%", 9: "88.840% and 100.00%", 10: "88.375% and 100.00%"}; 
  //return values[period];  
  return base_allocation_rate + "% and 100.00%";

}

window.get_yearly_charge_by_period = function(year) {
  if (year > CAP_PERIOD) {
    year = CAP_PERIOD;
  }

  var amc = get_base_rate(year);
  margin_rebate = get_margin_rebate();
  if (margin_rebate != 0.0) {
    var n = year * 4.0;

    //pmt function in excel
    var amc_quarterly = amc / ((1- (1 / Math.pow(1+CHARGE_INTEREST_RATE,n) ) ) / CHARGE_INTEREST_RATE);
    var amc = amc_quarterly*4.0*100.0;
  }
  else {
    amc = amc*100/year;
  }
  return amc;
}

window.get_yearly_charge_after_term_by_period = function(year) {
  //var rebate_additional_string = $("input#rebate_additional").val();
  //var rebate_additional_float = parseFloat(rebate_additional_string);
  //var percentage_of_commission = rebate_additional_float/100.0;
  var percentage_of_commission = get_rebate_additional();
  var charges = new Array();

  while (year < CAP_PERIOD) {
    year = year + 1;
    var amc_yearly = get_yearly_charge_by_period(year) - get_yearly_commission_by_period(year) * percentage_of_commission;
    //var amc_yearly = 0;
    var row = "Year " + year + ": " + to_string_with_2_decimals(amc_yearly) + "%";
    charges.push(row);   
  }

  var amc_yearly = get_yearly_charge_by_period(CAP_PERIOD) - get_yearly_commission_by_period(CAP_PERIOD) * percentage_of_commission;
  //var amc_yearly = 0;
  var row = "Capped at " + to_string_with_2_decimals(amc_yearly) + "%";
  charges.push(row);
  return charges.join("<br/>");
}

window.get_yearly_commission_by_period = function(year) {
  //var yearly_commission = COMMISSION_BASE_RATE;
  
  //var ifa_rebate = $("input#rebate_input").val();
  //ifa_rebate = ifa_rebate/100.0;

  //var adjusted_commission = $("input#adjusted_commission_hidden").val();
  //adjusted_commission = adjusted_commission/100.0;

  //if (year == 5) {
  //  yearly_commission = COMMISSION_BASE_RATE * (1+adjusted_commission);
  //}
  //else if (year > 5) {
  //  yearly_commission = (COMMISSION_BASE_RATE * (1+adjusted_commission))/5 * Math.pow(Math.pow((0.8/1.1),(1/5.0)),(year-5)) * year;
  //}
  //else if (year < 5) {
  //  yearly_commission = (COMMISSION_BASE_RATE * (1+adjusted_commission))/5 * Math.pow(Math.pow((1/1.1),(1/4.0)),(5-year)) * year;
  //}

  //yearly_commission = (yearly_commission - ifa_rebate)/year;
  yearly_commission = get_base_commission_rate_per_period(year)/year;
  return yearly_commission*100.0;
}

window.get_yearly_commissions_after_term_by_period = function(year) {

  //var rebate_additional_string = $("input#rebate_additional").val();
  //var rebate_additional_float = parseFloat(rebate_additional_string);
  //var percentage_of_commission = (100.0 - rebate_additional_float)/100.0;

  var percentage_of_commission = 1.0-get_rebate_additional();

  var commissions = new Array();
  while (year < CAP_PERIOD) {
    year = year + 1;
    var yearly_commission = get_yearly_commission_by_period(year) * percentage_of_commission;
    var row = "Year " + year + ": " + to_string_with_2_decimals(yearly_commission) + "%";
    commissions.push(row);
    
  }
    var yearly_commission = get_yearly_commission_by_period(CAP_PERIOD) * percentage_of_commission;
    var row = "Capped at " + to_string_with_2_decimals(yearly_commission) + "%";
    commissions.push(row);
  return commissions.join("<br/>");
}

window.validate_allocation_rate = function(allocation_rate_string) {
  allocation_rate = parseFloat(allocation_rate_string);
  base_allocation_rate = get_base_allocation_rate_per_period(get_period());
  value = false;
  if (allocation_rate >= base_allocation_rate) {
    if (allocation_rate <= 100.000) {
     value = true;     
    }
  }
  else {
    var allocation_rate_difference = allocation_rate - base_allocation_rate;
    if (Math.abs(allocation_rate_difference) < 0.01) {
        value = true;
    }  
  }
  return value;
}

window.validate_advisor_fee = function(advisor_fee_string) {
  advisor_fee = parseFloat(advisor_fee_string);
  value = false;
  if ((advisor_fee >= 0.0) && (advisor_fee <= 1.5)) {
    value = true;
  }
  return value;
}

window.validate_rebate_additional = function(rebate_additional_string) {
  rebate_additional = parseFloat(rebate_additional_string);
  value = false;
  if ((rebate_additional >= 0.0) && (rebate_additional <= 100.0)) {
    value = true;
  }
  return value;
}

window.validate_rebate_on_trail_on_policy_return = function(rebate_on_trail_on_policy_return_string) {
  rebate_on_trail_on_policy_return = parseFloat(rebate_on_trail_on_policy_return_string);
  value = false;
  if ((rebate_on_trail_on_policy_return >= 0.0) && (rebate_on_trail_on_policy_return <= 100.0)) {
    value = true;
  }
  return value;
}

window.to_string_with_2_decimals = function(num) {
  var decimals = 2;
  //var rounded_float = Math.round(num*Math.pow(10,decimals))/Math.pow(10,decimals);
  var rounded_float = Math.floor(num*Math.pow(10,decimals))/Math.pow(10,decimals);
  return rounded_float.toFixed(decimals).toString();
}

window.constant_structure = function() {
  var period_string = $("select#period").val();
  return ((period_string == "0") || (period_string == "-5") || (period_string == "-8") || (period_string == "-10"));
}

window.constant_structure_5_years = function() {
  var period_string = $("select#period").val();
  return (period_string == "-5");
}

window.constant_structure_8_or_10_years = function() {
  var period_string = $("select#period").val();
  return ((period_string == "-8") || (period_string == "-10"));
}

window.get_period = function() {
  var period_string = $("select#period").val();
  //set order period
  $("input#order_period").val(period_string);

  var period_int = parseInt(period_string);
  if (period_int == 0) {
    period_int = 1;
  }
  else if (period_int == -5) {
    period_int = 5;
  }
  else if (period_int == -8) {
    period_int = 8;
  }
  else if (period_int == -10) {
    period_int = 10;
  }    
  return period_int;
}

window.get_rebate_additional = function() {
  var rebate_additional_string = $("input#rebate_additional").val();
  var rebate_additional_float = parseFloat(rebate_additional_string);
  if (isNaN(rebate_additional_float)) {
    rebate_additional_float = 0.0; 
  }  
  return rebate_additional_float/100.0;
}

window.set_defaults = function() {
  //var period_int = get_period();
  //set_margin_rebate_hidden(constant_structure());  
  //base_allocation_rate = get_base_allocation_rate_per_period(period_int);
  //base_allocation_rate_string = to_string_with_2_decimals(base_allocation_rate);
  //$("input#allocation_rate_input").val(base_allocation_rate_string);
  //$("#allocation_rate_text").css('color', '#737373');
  //$("#allocation_range").css('color', '#737373');
  //$("#allocation_rate_input").css('border-color', '#ccc'); 

  policy_fee = get_policy_fee_by_currency("USD");
  transaction_charge = get_transaction_charge_by_currency("USD");
  custody_fee = get_custody_fee_by_currency("USD");
  $("#annual_policy_fee").text("USD " + policy_fee);
  $("#transaction_charge").text("USD " + transaction_charge);
  $("#custody_fee").text("USD " + custody_fee);  
  $("#transaction_charge_hidden").val("USD " + transaction_charge);
  $("#custody_fee_hidden").val("USD " + custody_fee);   
  $("input#annual_policy_fee_hidden").val("USD " + policy_fee);
  set_margin_rebate_hidden(constant_structure()); 

  return true;
}

window.update_defaults = function() {
  var period_int = get_period();
  set_margin_rebate_hidden(constant_structure()); 
  base_allocation_rate = get_base_allocation_rate_per_period(period_int);
  base_allocation_rate_string = to_string_with_2_decimals(base_allocation_rate);
  base_allocation_rate_string = "100"
  $("input#allocation_rate_input").val(base_allocation_rate_string);
  $("#allocation_rate_text").css('color', '#737373');
  $("#allocation_range").css('color', '#737373');
  $("#allocation_rate_input").css('border-color', '#ccc'); 

  //policy_fee = get_policy_fee_by_currency("USD");

  //$("#annual_policy_fee").text("USD " + policy_fee);
  //$("#annual_policy_fee_after").text("USD " + policy_fee);
  //$("input#annual_policy_fee_hidden").val("USD " + policy_fee);

  return true;
}

window.get_allocation_rate = function() {
  var allocation_rate_string = $("input#allocation_rate_input").val();
  var allocation_rate_float = 0.0;
  if (allocation_rate_string != null) {
    allocation_rate_string = allocation_rate_string.replace(",",".");
    allocation_rate_float = parseFloat(allocation_rate_string);
  }
  return allocation_rate_float;
}

window.set_margin_rebate_hidden = function(is_constant_structure) {
  var margin_rebate_hidden = $("input#margin_rebate_normal_hidden").val();
  if (is_constant_structure) {     
    margin_rebate_hidden = $("input#margin_rebate_constant_hidden").val();
  }
  $("input#margin_rebate_hidden").val(margin_rebate_hidden);
  return true;  
}

window.update_charge_fields = function() {
    var period_int = get_period();

    var base_allocation_rate = get_base_allocation_rate_per_period(period_int);
    var base_allocation_rate_string = to_string_with_2_decimals(base_allocation_rate);
    var allocation_rate = get_allocation_rate();

    investment_allocation = get_investment_allocation_from_allocation_rate(allocation_rate);
    investment_allocation_string = to_string_with_2_decimals(investment_allocation);
    $("#investment_allocation").text(investment_allocation_string + "%");
    $("input#investment_allocation_hidden").val(investment_allocation_string + "%");  
    allocation_range_text = get_allocation_range(base_allocation_rate_string);
    $("#allocation_range").text(allocation_range_text);

    var ec_upfront = 100.0 - allocation_rate.toFixed(10);
    var ec_upfront_string = to_string_with_2_decimals(ec_upfront);

    var allocation_rate_difference = allocation_rate - base_allocation_rate;

    if (Math.abs(allocation_rate_difference) < 0.01) {
      allocation_rate_difference = 0.0;
    }
    var pv = allocation_rate_difference/100.0;
    var n = period_int * 4.0;
    
    if (constant_structure_5_years()) {
      //pmt function in excel
      //var ec_quarterly = pv / ((1- (1 / Math.pow(1+CHARGE_INTEREST_RATE_CONSTANT_STRUCTURE_5_YEARS,n) ) ) / CHARGE_INTEREST_RATE_CONSTANT_STRUCTURE_5_YEARS);
      var ec_quarterly = pv / n;
      var ec_yearly = ec_quarterly*4.0*100.0;
      var ec_yearly_string = to_string_with_2_decimals(ec_yearly); 

      var amc_yearly_during = 0.5;
      $("input#amc_basis_text_hidden").val("Policy value");
      $("#amc_basis_text").text("Policy value");
    }
    else if (constant_structure_8_or_10_years()) {   
      var ec_quarterly = pv / n;
      var ec_yearly = ec_quarterly*4.0*100.0;
      var ec_yearly_string = to_string_with_2_decimals(ec_yearly); 

      var amc_yearly_during = 0.5;
      $("input#amc_basis_text_hidden").val("Policy value");
      $("#amc_basis_text").text("Policy value");
    } 
    else {
      //pmt function in excel
      var ec_quarterly = pv / ((1- (1 / Math.pow(1+CHARGE_INTEREST_RATE,n) ) ) / CHARGE_INTEREST_RATE);
      var ec_yearly = ec_quarterly*4.0*100.0;
      var ec_yearly_string = to_string_with_2_decimals(ec_yearly);   

      var amc_yearly_during = (ec_upfront/period_int + ec_yearly) * (1.0-get_rebate_on_trail_on_policy_return_for_amc());
      $("input#amc_basis_text_hidden").val("Policy value exceeding the original premium");
      $("#amc_basis_text").text("Policy value exceeding the original premium");      
    }
    var amc_yearly_during_string = to_string_with_2_decimals(amc_yearly_during) + "%";

    var amc_yearly_after_string = get_yearly_charge_after_term_by_period(period_int);
    
    $("#ec_upfront").text(ec_upfront_string);
    $("#ec_yearly").text(ec_yearly_string);
    $("#amc_yearly_during").text(amc_yearly_during_string);

    $("#ec_upfront").fadeIn(100).fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100); 
    $("#ec_yearly").fadeIn(100).fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100); 
    $("#amc_yearly_during").fadeIn(100).fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100); 

    $("input#ec_upfront_hidden").val(ec_upfront_string);
    $("input#ec_yearly_hidden").val(ec_yearly_string);
    $("input#amc_yearly_during_hidden").val(amc_yearly_during_string);

    if (constant_structure()) {  
      $("#amc_yearly_after").text(amc_yearly_during_string);
      //$("#amc_yearly_capped_text").text("");
      $("#amc_yearly_after").fadeIn(100).fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100); 
      //$("#amc_yearly_after_capped").fadeIn(100).fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100);     
      $("input#amc_yearly_after_hidden").val(amc_yearly_during_string);
      //$("input#amc_yearly_after_capped_hidden").val("");  
    }
    else {
      //$("#amc_yearly_after").text(amc_yearly_after_string);
      $("#amc_yearly_after").html(amc_yearly_after_string).text(); 
      //$("#amc_yearly_capped_text").html(amc_yearly_after_capped_string).text();
      $("#amc_yearly_after").fadeIn(100).fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100); 
      //$("#amc_yearly_capped_text").fadeIn(100).fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100);     
      $("input#amc_yearly_after_hidden").val(amc_yearly_after_string);
      //$("input#amc_yearly_after_capped_hidden").val(amc_yearly_after_capped_string);        
    }
  return true; 
}

window.get_rebate_on_trail_on_policy_return = function() {
  var rebate_on_trail_on_policy_return_string = $("input#rebate_on_trail_on_policy_return").val();
  var rebate_on_trail_on_policy_return_float = parseFloat(rebate_on_trail_on_policy_return_string);
  if (isNaN(rebate_on_trail_on_policy_return_float)) {
    rebate_on_trail_on_policy_return_float = 0.0; 
  }
  return rebate_on_trail_on_policy_return_float/100.0;
}

window.get_rebate_on_trail_on_policy_return_for_amc = function() {
  var rebate_on_trail_on_policy_return = get_rebate_on_trail_on_policy_return();
  var policy_return_commission = get_policy_return_commission();
  var trail_during = get_trail_during();
  var total_trail_commission = policy_return_commission + trail_during;
  var factor = 1.0;
  if (total_trail_commission != 0.0) {
    factor = policy_return_commission / total_trail_commission;
  }
  var rebate_on_trail_on_policy_return_for_amc = rebate_on_trail_on_policy_return * factor;
  return rebate_on_trail_on_policy_return_for_amc;
}

window.get_upfront_commission = function() {
  //var trail_ratio = $("input#trail").val();
  var period_int = get_period();
  var commission_base_rate = get_base_commission_rate_per_period(period_int);
  return (1 - get_trail_ratio()) * commission_base_rate * 100.0;
}

window.get_policy_return_commission = function() {
  return get_upfront_commission()/get_period(); 
}

window.get_rebated_policy_return_commission = function() {
  return get_policy_return_commission()*(1.0-get_rebate_on_trail_on_policy_return());
}

window.get_trail_during = function() {
  //var trail_ratio = $("input#trail").val();
  var period_int = get_period();
  var periods = period_int * 4;
  var commission_base_rate = get_base_commission_rate_per_period(period_int);
  var total_trail_commission = get_trail_ratio() * commission_base_rate;
  var trail_during = (total_trail_commission / ( (1-(1/Math.pow((1+COMMISSION_INTEREST_RATE),periods)))/COMMISSION_INTEREST_RATE )) * 400.0;
  return trail_during;
}

window.get_trail_ratio = function() {
  var trail_ratio_string = $("input#trail").val();
  var trail_ratio = parseFloat(trail_ratio_string);
  if (isNaN(trail_ratio)) {
    trail_ratio = 0.0;
    $("input#trail").val(trail_ratio); 
  } 

  if (trail_ratio < 0.0) {
    trail_ratio = 0.0;
    $("input#trail").val(trail_ratio);
  }
  else if (trail_ratio > 100.0) {
    trail_ratio = 100.0;
    $("input#trail").val(trail_ratio); 
  }
  $("input#order_trail_ratio").val(trail_ratio);

  return trail_ratio*0.01;
}

window.set_upfront_commission = function() {
  var upfront_commission = get_upfront_commission();
  var upfront_commission_string = to_string_with_2_decimals(upfront_commission); 
  $("#uc").text(upfront_commission_string);
  $("#uc").fadeIn(100).fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100);
  $("input#uc_hidden").val(upfront_commission_string);
  return true;  
}

window.set_trail_during = function() {
  var trail_during = get_trail_during();
  if (constant_structure_5_years()) {
    var default_trail_during_constant_structure_hidden = $("input#default_trail_during_constant_structure_hidden").val();
    trail_during = trail_during + parseFloat(default_trail_during_constant_structure_hidden);
  } 
  else if (constant_structure_8_or_10_years()) {
    var default_trail_during_constant_structure_hidden = $("input#default_trail_during_constant_structure_hidden").val();
    trail_during = trail_during + parseFloat(default_trail_during_constant_structure_hidden);
  }   
  var trail_during_string = to_string_with_2_decimals(trail_during);
  $("#tc_during").text(trail_during_string);
  $("#tc_during").fadeIn(100).fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100);
  $("input#tc_yearly_hidden").val(trail_during_string);
  return true;  
}

window.set_trail_after = function() {
  var period_int = get_period();
  var trail_after_string = get_yearly_commissions_after_term_by_period(period_int);
  if (constant_structure()) {
    if (constant_structure_5_years()) {
      var default_trail_after_constant_structure_hidden = $("input#default_trail_after_constant_structure_hidden").val();
      trail_after_string = to_string_with_2_decimals(default_trail_after_constant_structure_hidden) + "%";
    }
    else if (constant_structure_8_or_10_years()) {
      var default_trail_after_constant_structure_hidden = $("input#default_trail_after_constant_structure_hidden").val();
      trail_after_string = to_string_with_2_decimals(default_trail_after_constant_structure_hidden) + "%";
    }
    else {
      trail_after_string = to_string_with_2_decimals(get_yearly_commission_by_period(period_int)) + "%";
    }
  }
  $("#tc_yearly_after").html(trail_after_string).text(); 
  $("#tc_yearly_after").fadeIn(100).fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100);
  $("input#tc_yearly_after_hidden").val(trail_after_string);  
  return true;  
}

window.set_policy_return_commission = function() {
    var policy_return_commission = get_rebated_policy_return_commission();
    var policy_return_commission_string = to_string_with_2_decimals(policy_return_commission);
    $("#prtc_during").text(policy_return_commission_string);
    $("#prtc_during").fadeIn(100).fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100); 
    $("input#prtc_during_hidden").val(policy_return_commission_string);
  return true;    
}

window.update_commission_fields = function() {
    set_upfront_commission();
    set_trail_during();
    set_trail_after();
    set_policy_return_commission();
  return true;    
}

window.update_advisor_fee_fields = function() {
    var advisor_fee_string = $("input#advisor_fee").val();
    advisor_fee_string = advisor_fee_string.replace(",",".");
    var is_valid = validate_advisor_fee(advisor_fee_string);
  
    var advisor_fee_float = parseFloat(advisor_fee_string);
    if (isNaN(advisor_fee_float)) {
      advisor_fee_float = 0.0;
    }

      if (advisor_fee_float < 0.0) {
        advisor_fee_float = 0.0;
      }
      else if (advisor_fee_float > 1.5) {
        advisor_fee_float = 1.5;
      }

      advisor_fee_string = to_string_with_2_decimals(advisor_fee_float);

      if (advisor_fee_float == 0.0) {
        $("input#advisor_fee_hidden").val("");
        $(".advisor_fee_commission_div").hide();
        $(".advisor_fee_charge_div").hide();        
      }
      else {
        $("input#advisor_fee_hidden").val(advisor_fee_string+"%");
        $(".advisor_fee_commission_div").show();
        $(".advisor_fee_charge_div").show();        
      }
      $("#advisor_fee_commission").text(advisor_fee_string);
      $("#advisor_fee_charge").text(advisor_fee_string);

      $("#advisor_fee_text").css('color', '#737373');
      $("#advisor_fee").css('border-color', '#ccc');

      $("#advisor_fee_commission").fadeIn(100).fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100); 
      $("#advisor_fee_charge").fadeIn(100).fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100);   
        
      //set order adviser fee
      $("input#order_advisor_fee").val(advisor_fee_string);  

    if (!is_valid) {
      $("#advisor_fee_text").css('color', 'red');
      $("#advisor_fee").css('border-color', 'red');
      $("input#advisor_fee").val(advisor_fee_string);
    }  
  return true;    
}

window.update_rebate_additional_fields = function() {
    var rebate_additional_string = $("input#rebate_additional").val();
    rebate_additional_string = rebate_additional_string.replace(",",".");
    var is_valid = validate_rebate_additional(rebate_additional_string);

    var rebate_additional = parseFloat(rebate_additional_string);
    if (isNaN(rebate_additional)) {
      rebate_additional = 0.0;
    }    

    if (rebate_additional < 0.0) {
      rebate_additional = 0.0;
    }
    else if (rebate_additional > 100.0) {
      rebate_additional = 100.0;
    }

    rebate_additional_string = to_string_with_2_decimals(rebate_additional);

    $("#rebate_additional_text").css('color', '#737373');
    $("#rebate_additional").css('border-color', '#ccc');
    
    if (!is_valid) {
      $("input#rebate_additional").val(rebate_additional_string);
      $("#rebate_additional_text").css('color', 'red');
      $("#rebate_additional").css('border-color', 'red');
    } 
    update_commission_fields();
    update_charge_fields();

    $("input#order_rebate_additional").val(rebate_additional_string);

  return true;    
}

window.update_rebate_on_trail_on_policy_return_fields = function() {
    var rebate_on_trail_on_policy_return_string = $("input#rebate_on_trail_on_policy_return").val();
    rebate_on_trail_on_policy_return_string = rebate_on_trail_on_policy_return_string.replace(",",".");
    var is_valid = validate_rebate_on_trail_on_policy_return(rebate_on_trail_on_policy_return_string);

    var rebate_on_trail_on_policy_return = parseFloat(rebate_on_trail_on_policy_return_string);
    if (isNaN(rebate_on_trail_on_policy_return)) {
      rebate_on_trail_on_policy_return = 0.0;
    }        
    if (rebate_on_trail_on_policy_return < 0.0) {
      rebate_on_trail_on_policy_return = 0.0;
    }
    else if (rebate_on_trail_on_policy_return > 100.0) {
      rebate_on_trail_on_policy_return = 100.0;
    }

    rebate_on_trail_on_policy_return_string = to_string_with_2_decimals(rebate_on_trail_on_policy_return);

    $("#rebate_on_trail_on_policy_return_text").css('color', '#737373');
    $("#rebate_on_trail_on_policy_return").css('border-color', '#ccc');

    if (!is_valid) {
      $("input#rebate_on_trail_on_policy_return").val(rebate_on_trail_on_policy_return_string);
      $("#rebate_on_trail_on_policy_return_text").css('color', 'red');
      $("#rebate_on_trail_on_policy_return").css('border-color', 'red');
    }
    set_policy_return_commission();
    update_charge_fields();
    $("input#order_rebate_on_trail_on_policy_return").val(rebate_on_trail_on_policy_return_string);     
  return true;    
}

window.update_allocation_rate_fields = function() {
    var allocation_rate_string = $("input#allocation_rate_input").val();
    allocation_rate_string = allocation_rate_string.replace(",",".");
    is_valid = validate_allocation_rate(allocation_rate_string);

    var allocation_rate = parseFloat(allocation_rate_string);
    var base_allocation_rate = get_base_allocation_rate_per_period(get_period());

    if (isNaN(allocation_rate)) {
      allocation_rate = 100.0;
    }     

    if (allocation_rate < base_allocation_rate) {
      allocation_rate = base_allocation_rate;
    }
    else if (allocation_rate > 100.0) {
      allocation_rate = 100.0;
    }

    allocation_rate_string = allocation_rate.toString();
   
    $("#allocation_rate_text").css('color', '#737373');
    $("#allocation_range").css('color', '#737373');
    $("#allocation_rate_input").css('border-color', '#ccc'); 

    if (!is_valid) {
      $("#allocation_rate_input").val(allocation_rate_string);
      $("#allocation_rate_text").css('color', 'red');
      $("#allocation_range").css('color', 'red');
      $("#allocation_rate_input").css('border-color', 'red');
      //var base_allocation_rate = get_base_allocation_rate_per_period(get_period());
      var base_allocation_rate_string = to_string_with_2_decimals(base_allocation_rate);
      allocation_range_text = get_allocation_range(base_allocation_rate_string);
      $("#allocation_range").text(allocation_range_text);      
    }

    //set order allocation rate
    $("input#order_allocation_rate").val(allocation_rate_string);  
    update_charge_fields();  

  return true;    
}

$(document).on("turbolinks:load", function(){
  set_defaults();
  update_charge_fields();
  update_commission_fields();

  $(".advisor_fee_commission_div").hide();
  $(".advisor_fee_charge_div").hide();

  $("#submit_charging").click(function() {
    $("input#sheet_type").val("Charging Structure");
  });
  $("#submit_commission").click(function() {
    $("input#sheet_type").val("Commission Structure");
  });

  $("input#applicant").change(function(){
    var value_string = $(this).val();
    $("input#applicant_name").val(value_string);
  });

  $("input#invested_amount").change(function(){
    var value_string = $(this).val();
    $("input#amount").val(value_string);
    $("input#order_invested_amount").val(value_string);
  });

  $("select#currency").change(function(){ 
    var value_string = $(this).val();
    policy_fee = get_policy_fee_by_currency(value_string);
    transaction_charge = get_transaction_charge_by_currency(value_string);
    custody_fee = get_custody_fee_by_currency(value_string);

    $("#annual_policy_fee").text(value_string + " " + policy_fee);
    $("#transaction_charge").text(value_string + " " + transaction_charge);
    $("#custody_fee").text(value_string + " " + custody_fee);

    $("#annual_policy_fee").fadeIn(100).fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100); 
    $("#transaction_charge").fadeIn(100).fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100); 
    $("#custody_fee").fadeIn(100).fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100); 

    $("input#annual_policy_fee_hidden").val(value_string + " " + policy_fee);
    $("input#transaction_charge_hidden").val(value_string + " " + transaction_charge); 
    $("input#custody_fee_hidden").val(value_string + " " + custody_fee);   

    $("input#order_currency").val(value_string);
  });

  $("select#period").change(function(){ 
    update_defaults();    
    update_charge_fields();
    update_commission_fields();
  });

  $("input#rebate_input").change(function(){
    update_allocation_rate_fields();
    //update_charge_fields();
    update_commission_fields();
  });

  $("input#advisor_fee").change(function(){ 
    update_advisor_fee_fields();
  });

  $("input#rebate_additional").change(function(){ 
    update_rebate_additional_fields();   
  });

  $("input#rebate_on_trail_on_policy_return").change(function(){ 
    update_rebate_on_trail_on_policy_return_fields(); 
  });

  $("input#allocation_rate_input").change(function(){ 
    update_allocation_rate_fields();
  });

  $("input#trail").change(function(){ 
    update_commission_fields();
    update_charge_fields();       
  });


});