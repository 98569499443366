require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
import $ from "jquery";
//import 'bootstrap';
//import '../src/stylesheets/application.scss';
require('webpack-jquery-ui');
require('webpack-jquery-ui/css');

import bootstrap from 'bootstrap/dist/js/bootstrap.min.js';
window.bootstrap = bootstrap;

import '../src/javascripts/illustrations.js';
import '../src/javascripts/tasks.js';
import '../src/javascripts/custom.js';

import "controllers";
import flatpickr from "flatpickr";
require("flatpickr/dist/flatpickr.min.css");

require('bootstrap4-toggle/js/bootstrap4-toggle.min.js');
require("bootstrap4-toggle/css/bootstrap4-toggle.min.css");

//charts
require("chartkick");
require("chart.js");

import Masonry from 'masonry-layout';
window.Masonry = Masonry;
var imagesload = require('imagesloaded');

import Highcharts from 'highcharts';
import Timeline from 'highcharts/modules/timeline.js';
Timeline(Highcharts);

window.Highcharts = Highcharts;
require("highcharts/css/highcharts.css");

//sweet alerts
import swal from 'sweetalert';

import { createPopper } from '@popperjs/core';

//File upload
//import ActiveStorageDragAndDrop from 'active_storage_drag_and_drop'
//ActiveStorageDragAndDrop.start();

import Dropzone from "dropzone";
window.Dropzone = Dropzone;

const Choices = require('choices.js');
window.Choices = Choices;

document.addEventListener("turbolinks:load", () => {
  enable_flatpickr();
  set_dropzone();
  set_choices();
});