window.toggleSourceFields = function() {
  var kind = $("select#source_kind").val();
  if (kind == "annual_salary_plus_bonuses") {
    $(".salary_field").show(); 
    $(".occupation_field").show(); 
    $(".name_field").show(); 
    $(".industry_field").show(); 
    $(".date_field").show();   
    $(".source_field").hide();  
    $(".additional_field").hide();  

    $("label#label_amount").text("Income this year");
    $("label#label_date").text("Date retired");
  } 
  else if (kind == "other_unearned_income") {
    $(".salary_field").hide(); 
    $(".occupation_field").hide(); 
    $(".name_field").hide(); 
    $(".industry_field").hide(); 
    $(".date_field").show();   
    $(".source_field").show();  
    $(".additional_field").hide();  

    $("label#label_amount").text("Amount received");
    $("label#label_date").text("Date received");
    $("label#label_source").text("Received from");
  }     
  else if (kind == "savings") {
    $(".salary_field").hide(); 
    $(".occupation_field").hide(); 
    $(".name_field").hide(); 
    $(".industry_field").hide(); 
    $(".date_field").hide();   
    $(".source_field").show();  
    $(".additional_field").show();  

    $("label#label_amount").text("Amount");
    $("label#label_additional").text("How were savings accumulated?");
    $("label#label_source").text("Bank where savings where held");
  }   
  else if (kind == "pension_transfer") {
    $(".salary_field").hide(); 
    $(".occupation_field").hide(); 
    $(".name_field").hide(); 
    $(".industry_field").hide(); 
    $(".date_field").show();   
    $(".source_field").show();  
    $(".additional_field").hide();  

    $("label#label_amount").text("Amount received");
    $("label#label_date").text("Date received");
    $("label#label_source").text("Received from");
  }   
  else if (kind == "property_or_asset_sale") {
    $(".salary_field").hide(); 
    $(".occupation_field").hide(); 
    $(".name_field").hide(); 
    $(".industry_field").hide(); 
    $(".date_field").show();   
    $(".source_field").show();  
    $(".additional_field").show();  

    $("label#label_amount").text("Amount received");
    $("label#label_additional").text("Address of property sold or asset type");
    $("label#label_source").text("How long held?");
    $("label#label_date").text("Date of sale");
  }   
  else if (kind == "company_sale") {
    $(".salary_field").hide(); 
    $(".occupation_field").hide(); 
    $(".name_field").show(); 
    $(".industry_field").show(); 
    $(".date_field").show();   
    $(".source_field").hide();  
    $(".additional_field").hide();  

    $("label#label_amount").text("Amount received");
    $("label#label_date").text("Date received");
  }   
  else if ((kind == "lottery_or_betting_win") || (kind == "gift") || (kind == "inheritance") || (kind == "other_funds") || (kind == "policy_claim") || (kind == "compensation_payment")) {
    $(".salary_field").hide(); 
    $(".occupation_field").hide(); 
    $(".name_field").hide(); 
    $(".industry_field").hide(); 
    $(".date_field").show();   
    $(".source_field").show();  
    $(".additional_field").hide();  

    $("label#label_amount").text("Amount received");
    $("label#label_date").text("Date received");
    $("label#label_source").text("Source");
  }                                   
};

window.enable_source_toggle = function() {
  $("select#source_kind").change(function(){ 
    toggleSourceFields();
  });
};

window.security_choices_select = function() {
  $('select#order_security_id').change(function(){ 
    var security_id = $(this).val();
    if (security_id) {
      // Send the request and update sub category dropdown 
      $.ajax({
        dataType: "json",
        cache: false,
        url: '/securities/' + security_id,
        timeout: 3000,
        error: function(xhr, status, error){
          alert("Failed to submit : "+status+" ;"+error);
        },
        success: function(j, status, xhr){                   
          $("#order_currency").val(j.currency);
        }
      }); 
    } 
  });

};

window.set_choices_bank = function() {
  if ($('#account_bank_id').length) {
    var elem = document.getElementById('account_bank_id');
    var config = {
      placeholderValue: 'Start typing a bank name or BIC',
      shouldSort: false,
      searchFloor: 3,        // or whatever value makes sense for your data / API
      searchChoices: false,  // see bullet point 2 above
      duplicateItemsAllowed: false,  // this is ignored, see bullet point 3 above
      renderChoiceLimit: 20,
      removeItems: true,
      removeItemButton: true,
      placeholder: true
    };

    // Construct the Choices object.
    var choices = new Choices(elem, config);

    // Some config and bookkeeping for API calls.
    var apiUrl = '/banks';
    var lookupDelay = 100;
    var lookupTimeout = null;
    var lookupCache = {};

    // Function to perform the API lookup.
    var serverLookup = function() {
      // we can't use choices.currentValue because this is blank if we set searchChoices
      // to false. Instead we use the actual input field's value.
      var query = choices.input.value;
      if (query in lookupCache) {
        populateOptions(lookupCache[query]);
      } 
      else {
        $.ajax({
            dataType: "json",
            cache: false,
            url: apiUrl,
            type: 'get',
            data: { "term": query},
            timeout: 3000,
            error: function(xhr, status, error){
                alert("Failed to submit : "+ status+" ;"+error);
            },
            success: function(j, status, xhr){  
              lookupCache[query] = j;
              populateOptions(j);                                                                                                    
            }
        });       
      } 
    };

    // Function to actually populate the results from the API lookup.
    var populateOptions = function(options) {
      // Now actually set the (de-duped) results.
      choices.setChoices(options, 'value', 'label', true);
    };

    // Trigger an API lookup when the user pauses after typing.
    elem.addEventListener('search', function(event) {
      clearTimeout(lookupTimeout);
      lookupTimeout = setTimeout(serverLookup, lookupDelay);
    });

    // We want to clear the API-provided options when a choice is selected.
    elem.addEventListener('choice', function(event) {
      choices.setChoices([], 'value', 'label', true);
    });
  }
}

window.set_choices_security = function() {
  if ($('#order_security_id').length) {
    var dropDownSelects = new Choices('#order_security_id', {renderChoiceLimit: 8, removeItemButton: true });
  } 
}

window.set_choices_contact = function() {
  if ($('#portfolio_contact_contact_id').length) {
    var dropDownSelects = new Choices('#portfolio_contact_contact_id', {renderChoiceLimit: 8, removeItemButton: true });
  } 
}

window.set_choices_country = function() {
  if ($('#portfolio_contact_contact_attributes_nationality').length) {
    var dropDownSelects = new Choices('#portfolio_contact_contact_attributes_nationality', {removeItemButton: true });
  } 
  if ($('#portfolio_contact_contact_attributes_country_of_birth').length) {
    var dropDownSelects = new Choices('#portfolio_contact_contact_attributes_country_of_birth', {removeItemButton: true });
  } 
  $( "body" ).find(".country-choices").each(function() {
    var id = $(this).attr("id");
    var dropDownSelects = new Choices("#"+id, {removeItemButton: true });
  });  
}

window.enable_adviser_premium_setting = function() {
  $("select#order_adviser_premium_setting_id").change(function(){ 
    var id = $(this).val();
    if (id == "") { 
      $("select#order_contract_period").val(""); 
      $("input#order_rebate_points_ifa").val("");
      $("input#order_rebate_additional").val("");
      $("input#order_rebate_on_trail_commission_on_policy_return").val("");    
      $("input#order_allocation_rate").val("");     
      $("input#order_trail_ratio").val("");      
    }
    else {
        // Send the request and update sub category dropdown 
        $.ajax({
            dataType: "json",
            cache: false,
            url: '/premium_settings/' + id,
            timeout: 2000,
            error: function(xhr, status, error){
                alert("Failed to submit : "+ status+" ;"+error);
            },
            success: function(j, status, xhr){  
                $("select#order_contract_period").val(j.establishment_period); 
                $("input#order_rebate_points_ifa").val(j.rebate_points_ifa);
                $("input#order_rebate_additional").val(j.rebate_additional); 
                $("input#order_rebate_on_trail_commission_on_policy_return").val(j.rebate_on_trail_commission_on_policy_return);    
                $("input#order_allocation_rate").val(j.allocation_rate); 
                $("input#order_trail_ratio").val(j.trail_ratio);                                                                                                                         
             }
        }); 
    }   
  });
} 