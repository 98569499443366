$(document).on('page:change', function() {
   reenable_submittor();
});

$(document).on('turbolinks:click', function() { $('body').css( 'cursor', 'progress' ); });

$(document).on("turbolinks:load", function() {
  set_checkbox_switch();
  
  //enable_contact_toggle();
  security_choices_select();
  toggleSourceFields();
  enable_source_toggle();

  //togglePolicyHolderFields();
  
  $(".to_hide").hide();

  $('body').css( 'cursor', 'default' );

  enable_submittor();
  enable_adviser_premium_setting();

  //Hide spinner when hitting Back in Browser
  $('.spinner').hide();
  $('.spinner-link').show();

    $("#toggle_security_history").click(function(){
        $(".zero").toggle();
        if ($("#toggle_security_history").hasClass("show")) {
            //set icon
            $("#toggle_security_history").html("<i class='fas fa-minus-square'></i> Show current");
        }
        else {
            $("#toggle_security_history").html("<i class='fas fa-plus-square'></i> Show all");
        }
        $("#toggle_security_history").toggleClass("show");
    });

    set_timeline();
    set_masonry();
    enable_add_fields();
});

//Replace Browser confirmation alert
$(document).on('confirm', function (event) {
  var link = $(event.target);
  var message = link.data("confirm");
  if (message == undefined){
    return true;
  }

  swal({
    title: "Please confirm",
    text: message,
    icon: "warning",
    buttons: true,
    dangerMode: true,    
  })
  .then((willProceed) => {
    if (willProceed) {
        // User hits OK
        // Remove data-confirm
        link.data("confirm", null);
        link.removeAttr('data-confirm');
        // Re-click link
        link[0].click();
        // Replace data-confirm (in case of AJAX update, still want prompt next time)
        //link.data("confirm", message);
        //$('.spinner').hide();
        //$('.spinner-link').show();
    } else {
        //link.data("confirm", null);
        $('.spinner').hide();
        $('.spinner-link').show();
    }
  });

  // Prevent rails from popping up a browser box, we've already done the work
  return false;
});

$(document).ajaxError(function(event,jqxhr,options,exc) {
  alert(jqxhr.responseText);
    var errors = JSON.parse(jqxhr.responseText);
    
    var er = jqxhr.responseText;
    var er = "";
    for(var i = 0; i < errors.length; i++){
        var list = errors[i];
        er += "<div class='list-group-item list-group-item-danger'>"+list+"</div>"
    }
    $(".errors").html(er);   
});

//Show Turbolinks' ProgressBar when calling custom AJAX requests
$(document).ajaxSend(function(event, request, settings) {
    Turbolinks.controller.adapter.progressBar.setValue(0);
    Turbolinks.controller.adapter.progressBar.show();
    $('body').css( 'cursor', 'progress' );
});

//And hide it when done loading
$(document).ajaxComplete(function(event, request, settings) {
    Turbolinks.controller.adapter.progressBar.setValue(100);
    Turbolinks.controller.adapter.progressBar.hide();
    $('body').css( 'cursor', 'default' );
});

window.set_masonry = function() {

  if ($('.masonry-container').length) {
      var msnry = new Masonry( '.masonry-container', {
        percentPosition: true
      }); 
  }
 

  $('a[data-bs-toggle=tab]').each(function () {
    $(this).on('shown.bs.tab', function () {

      var a_href = $(this).attr('href');

      var msnry = new Masonry( a_href, {
        percentPosition: true
      });  
    
    });
});
}

window.set_dropzone = function() {
  if ($('#new_document').length) {

var previewNode = document.querySelector("#template");
previewNode.id = "";
var previewTemplate = previewNode.parentNode.innerHTML;
previewNode.parentNode.removeChild(previewNode);

    var myD2 = new Dropzone("form#new_document", {
      paramName: "document[attachment]",
      autoProcessQueue: false,
      maxFiles: 1,
      clickable: ".fileinput-button",
      previewTemplate: previewTemplate,
      previewsContainer: "#previews",
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      accept: function(file, done) {
        done();
      },
      init: function() {
        var myDropzone = this;
        var form = document.getElementById('new_document');
        form.addEventListener('submit', function(event) {
          // stop the form's submit event
          if(myDropzone.getQueuedFiles().length > 0){
            event.preventDefault();
            event.stopPropagation();
            myDropzone.processQueue();
          }
        });
        myDropzone.on("sending", function(file, xhr, formData) {
          formData.append("document[documentable_id]", $('#document_documentable_id').val());
          formData.append("document[documentable_type]", $('#document_documentable_type').val());
          formData.append("document[kind]", $('#document_kind').val());
          var sow_kind = $('#document_sow_kind').val();
          if (sow_kind != undefined) {
            formData.append("document[sow_kind]", sow_kind);
          }
        });
      },
      success: function(data, response) {
        window.location.href = ("/contacts/"+response.documentable_id);
      }
    });
  }
}

window.set_timeline = function() {
  var timeline_items = $('#timeline').data('items');
    if(typeof timeline_items !== 'undefined') {
      Highcharts.chart('timeline', {
        chart: {
          type: 'timeline',
          styledMode: true
        },
        xAxis: {
          visible: false
        },
        yAxis: {
          visible: false
        },
        title: {
          text: ''
        },  
        series: [{
          data: timeline_items
        }]
      });
    }
}

window.set_choices = function() {
  set_choices_bank();
  set_choices_security();
  set_choices_contact(); 
  set_choices_country();
}

window.set_checkbox_switch = function() {
  $('.bootstrap-toggle').bootstrapToggle();
    //$(".switch").bootstrapSwitch().trigger('change');
    //$(".switch").each(function () {
      //$(this).bootstrapSwitch("state", $(this).is(':checked')).trigger('change');
    //  $(this).bootstrapSwitch().trigger('change');
    //});
};

window.toggle_modal = function() {
  var modal = bootstrap.Modal.getInstance(document.getElementById('dialog'));
  modal.toggle();
};

window.set_dialog = function(title, body, focus) {

    // Add the dialog title
    $('.modal-title').html(title);     
    // Render the new form
    $('.modal-body').html(body);     
    // Show the dynamic dialog
    var modal = bootstrap.Modal.getInstance(document.getElementById('dialog'));
    if (modal == null) {
      var modal = new bootstrap.Modal(document.getElementById('dialog'));
    }
    modal.show();

    enable_flatpickr();

    $(focus).focus();

    $('.spinner').hide();    

    reenable_submittor(); 
    //set_checkbox_switch();
    enable_add_fields();
};

window.set_toast = function(message, type) {
    //$.toast({
    //    title: message,
    //    type: type,
    //    delay: 5000
    //});

    swal(message, {
        buttons: false,
        timer: 1500,
        icon: type
    });
};
window.enable_flatpickr = function() {
  flatpickr("[data-behaviour='flatpickr']", {
    altInput: false,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d",
    allowInput: true
  })
};
window.reenable_submittor = function() {
    $('input:disabled').show();
    $('input:disabled').next('.spinner').hide();
    $('input:disabled').removeAttr('disabled');
    $('.spinner').hide();
    $('.spinner-link').show();
};

window.enable_submittor = function() {
    $('form.safe_submit').submit(function() {
        $("input[type='submit']", this).attr('disabled', 'disabled');
        $("input[type='submit']", this).next('.spinner').show();
        $("input[type='submit']", this).hide();
        return true;
    });

    $(".spinner-link").click(function() {
        $(this).next('.spinner').show();
        $(this).hide();
    });    
};


//function add_fields(link, association, content) {
//    var new_id = new Date().getTime();
//    var regexp = new RegExp("new_" + association, "g")
//    $(link).parent().before(content.replace(regexp, new_id));
//    $(link).parent().prev().find(".flatpickr").flatpickr({altInput: false, altFormat: "F j, Y", dateFormat: "Y-m-d", allowInput: true });
//}

window.enable_add_fields = function() {
    $("[data-form-prepend]").click(function(e) {
      var id = null;
        var obj = $($(this).attr("data-form-prepend"));
        obj.find("input, select, textarea").each(function() {
          var datetime = new Date().getTime();
          $(this).attr("name", function() {
                return $(this)
                .attr("name")
                .replace("new_record", datetime);
          });
          $(this).attr("id", function() {
                return $(this)
                .attr("id")
                .replace("new_record", datetime);
          });  
          if ($(this).hasClass("country-choices")) {
            id = $(this).attr("id");
          }                  
        });       
        obj.insertBefore($(this).parent());
        //$(this).parent().prev().find(".flatpickr").flatpickr({altInput: false, altFormat: "F j, Y", dateFormat: "Y-m-d", allowInput: true });
        //var country_elem = $(this).parent().prev().find(".country-choices").first();
        //alert(country_elem);
        if (id != null) {
          var dropDownSelects = new Choices("#"+id, {removeItemButton: true });
        }
        return false;
    });
}

window.remove_fields = function(link) {
    $(link).prev("input[type=hidden]").val("1");
    $(link).closest(".fields").remove();    
};

window.add_row = function(target, content) {
    $(target).prepend(content);
};

window.update_row = function(target, content) {
    $(target).replaceWith(content);
};